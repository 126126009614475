<template>
  <section class="bx-teaser bx-vod-teaser-for-epg bx-typo--vod-epg"
           style="margin-bottom: 2.3em;">
    <h3 style="cursor: default;">
      Wer streamt {{ translatedObjectTypeWithArticle }} &bdquo;{{ title }}&ldquo;?
    </h3>
    <a :href="url">
      <figure v-if="posterUrl"
              style="background-color: #132350; border-radius: .4em; text-align: center; padding: 1.5em;">
        <img :src="posterUrl"
             :alt="poster.imageAltText"
             :title="poster.imageAltText"
             style="max-width: 266px;">
      </figure>
    </a>
  </section>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../stores/config'
import { usePageStore } from '../../stores/page'
import imgSrcSet from '../../mixins/img-src-set'

export default {
  mixins: [imgSrcSet],
  props: {
    objectType: {
      type: String,
      default: 'Film'
    },
    title: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    poster: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']), // this store prop is only used by a mixin
    ...mapState(usePageStore, ['brandFromStore']),
    translatedObjectTypeWithArticle () {
      return this.objectType === 'movie' ? 'den Film' : 'die Serie'
    },
    posterUrl () {
      return this.poster?.imageId
        ? this.createImgSrc({
          image: this.poster,
          brand: this.brandFromStore,
          type: 'png',
          setName: 'bx-teaser-container--vod'
        })
        : null
    }
  }
}
</script>
