<template>
  <ParagraphTypeEpgSearch :config="config"
                          :initial-filters="initialFilters"
                          :initial-broadcasts="initialBroadcasts" />
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    initialFilters: {
      type: Object,
      default: () => {}
    },
    initialBroadcasts: {
      type: Array,
      default: () => []
    }
  }
}
</script>
